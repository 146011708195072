import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 695.000000 695.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,695.000000) scale(0.100000,-0.100000)">
		<path d="M3467 6943 l3473 -3 3 -3473 2 -3472 3 3478 2 3477 -3477 -2 -3478
-3 3472 -2z"/>
<path d="M3330 5299 c-203 -18 -424 -81 -605 -172 -74 -37 -243 -143 -270
-170 -6 -7 -31 -28 -55 -47 -50 -40 -63 -53 -140 -137 -111 -121 -170 -194
-170 -211 0 -5 -7 -15 -15 -22 -14 -12 -80 -122 -101 -170 -6 -14 -20 -45 -31
-70 -38 -84 -60 -155 -74 -235 -10 -61 -15 -73 -19 -51 -3 16 -6 -71 -8 -195
-2 -155 -7 -226 -15 -231 -8 -5 -6 -9 6 -14 13 -4 17 -18 17 -53 0 -49 36
-211 70 -311 11 -33 18 -70 15 -83 -2 -12 2 -35 10 -52 15 -30 14 -17 -1 36
-4 14 -2 19 5 15 6 -4 11 -11 11 -15 0 -5 8 -26 17 -47 9 -22 16 -48 14 -59
-1 -13 1 -16 6 -8 9 13 80 -113 74 -132 -1 -5 1 -7 4 -3 9 8 65 -65 65 -84 1
-7 7 -24 16 -38 l15 -25 -7 25 -6 25 26 -25 c24 -22 25 -25 9 -31 -17 -7 -17
-8 1 -8 23 -1 105 -61 171 -125 98 -95 277 -203 430 -259 62 -23 272 -63 282
-54 2 2 -23 10 -54 17 -212 46 -430 155 -499 248 -15 20 -49 58 -76 85 -73 72
-40 56 53 -25 8 -8 21 -12 27 -10 7 2 15 -3 18 -12 3 -9 10 -13 15 -10 5 3 15
1 23 -3 14 -7 16 -12 16 -30 0 -5 4 -2 9 6 7 11 24 9 97 -12 110 -31 167 -39
266 -39 412 3 791 180 1024 478 118 151 168 293 169 479 0 106 -3 130 -29 208
-62 189 -206 342 -378 401 -24 8 -51 17 -60 21 -10 4 -18 3 -18 -3 0 -5 21
-17 48 -27 26 -10 61 -32 78 -49 42 -41 61 -56 74 -56 5 0 10 -4 10 -8 0 -5
25 -35 55 -67 30 -33 55 -65 55 -73 0 -7 5 -10 11 -6 7 4 10 1 7 -7 -2 -7 6
-29 19 -48 13 -18 23 -45 23 -58 0 -13 4 -32 9 -41 4 -9 11 -37 14 -62 3 -25
3 -38 0 -30 -12 28 -48 59 -73 63 -14 2 -34 17 -45 33 -11 16 -32 43 -48 59
-15 17 -30 38 -33 48 -3 9 -8 17 -12 17 -10 0 -62 46 -62 55 0 4 -20 30 -45
57 -25 28 -45 54 -45 59 0 4 -16 16 -35 25 -20 9 -43 30 -51 45 -8 16 -19 29
-24 29 -4 0 -15 14 -24 31 l-15 31 52 -6 52 -5 -60 15 c-33 9 -114 19 -180 22
l-120 7 -3 160 c-2 88 0 175 3 194 l6 34 132 -6 c73 -3 146 -11 162 -18 17 -7
38 -11 48 -10 9 1 17 -2 17 -6 0 -5 17 -15 38 -22 69 -25 217 -161 273 -251
13 -22 27 -40 32 -40 4 0 7 120 7 268 l0 267 -479 -3 -480 -2 -5 -53 c-8 -81
-14 -328 -6 -277 9 59 10 -762 1 -1083 -4 -152 -2 -252 4 -258 10 -10 129 -12
199 -3 l46 7 0 483 c0 265 3 489 6 498 4 11 19 16 52 16 51 0 152 -21 152 -31
0 -4 15 -10 33 -13 18 -3 49 -17 67 -31 19 -14 37 -25 41 -25 9 0 99 -87 99
-96 0 -4 14 -29 30 -56 17 -26 30 -57 30 -67 0 -11 5 -21 11 -23 8 -3 13 -48
17 -125 4 -99 2 -127 -12 -153 -9 -18 -16 -39 -16 -46 0 -24 -71 -142 -121
-201 -39 -46 -138 -117 -224 -161 -132 -68 -171 -74 -504 -79 -162 -3 -322 -2
-356 2 -56 6 -67 11 -108 50 -98 96 -206 339 -237 535 -5 36 -10 122 -10 191
0 192 35 365 104 511 20 42 36 80 36 84 0 7 60 105 70 114 3 3 21 25 40 50 70
92 216 225 322 292 50 33 133 74 193 97 33 13 74 29 91 37 140 62 433 78 626
35 59 -13 105 -28 102 -32 -3 -5 0 -9 5 -9 6 0 11 -7 11 -15 0 -8 5 -15 10
-15 14 0 13 13 -2 29 -18 19 25 5 81 -26 24 -13 48 -22 53 -21 24 8 -119 225
-206 310 -50 50 -61 72 -20 39 14 -11 32 -17 40 -14 8 3 14 0 14 -6 0 -6 9
-11 19 -11 11 0 26 -6 33 -13 7 -8 20 -14 28 -15 31 -2 102 -23 123 -36 12 -8
40 -19 62 -26 22 -7 68 -30 103 -52 34 -21 67 -38 72 -38 6 0 10 -4 10 -10 0
-5 15 -16 33 -23 17 -8 38 -21 46 -30 7 -10 17 -17 22 -17 16 0 128 -85 129
-97 0 -7 10 -18 23 -24 27 -15 112 -104 135 -142 9 -15 24 -29 33 -32 9 -3 24
-21 33 -39 9 -19 23 -37 32 -40 10 -4 13 -13 9 -25 -4 -12 2 -23 18 -34 13 -8
29 -33 37 -56 7 -23 20 -47 28 -53 17 -15 34 -55 38 -88 1 -14 8 -38 14 -55
14 -32 15 -38 26 -130 3 -33 11 -63 15 -66 5 -3 9 -25 10 -50 0 -24 0 -55 0
-69 1 -14 -2 -38 -5 -55 -5 -27 -5 -27 3 -5 8 22 9 20 10 -18 1 -26 -3 -41 -9
-37 -6 4 -10 -7 -10 -24 0 -17 4 -31 9 -31 5 0 13 -15 16 -32 4 -18 15 -58 25
-88 45 -136 33 -133 -20 5 -58 149 -170 315 -290 428 -38 37 -70 71 -70 76 0
24 -65 151 -77 151 -12 0 -12 -2 0 -14 8 -8 19 -36 26 -63 7 -26 16 -55 21
-64 5 -9 7 -27 4 -40 -4 -20 -3 -21 4 -5 8 18 9 18 15 2 4 -10 7 -25 7 -34 0
-15 -2 -15 -10 -2 -5 8 -10 10 -10 4 0 -6 9 -18 20 -25 15 -11 23 -34 31 -94
16 -109 16 -304 0 -395 -14 -82 -87 -307 -106 -326 -7 -7 -18 -26 -25 -43 -7
-17 -16 -28 -20 -26 -5 3 -12 0 -16 -6 -4 -8 -3 -9 4 -5 7 4 12 4 12 0 0 -10
-79 -123 -101 -145 -10 -10 -25 -29 -35 -44 -9 -14 -22 -24 -29 -21 -7 2 -12
-3 -11 -12 1 -10 -6 -16 -16 -16 -10 1 -15 -2 -13 -7 11 -17 -139 -135 -233
-183 -28 -14 -86 -37 -129 -52 -75 -25 -113 -47 -113 -66 0 -24 -129 -180
-142 -171 -6 3 -8 1 -4 -5 5 -9 -7 -21 -60 -59 -15 -11 -46 -29 -114 -65 -48
-25 -58 -34 -45 -40 17 -10 245 -6 262 5 6 4 22 6 34 6 33 -2 151 19 234 41
500 134 868 463 1032 925 51 142 74 277 80 468 9 294 -18 459 -107 642 -283
582 -771 937 -1368 995 -110 11 -170 11 -292 -1z m767 -1721 c-3 -7 -5 -2 -5
12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m940 -150 c-3 -7 -5 -2 -5 12 0 14 2 19
5 13 2 -7 2 -19 0 -25z m-2997 -178 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3
6 -1 10 4 10 6 0 11 -4 11 -10z m34 -36 c5 -14 4 -15 -9 -4 -17 14 -19 20 -6
20 5 0 12 -7 15 -16z m66 -49 c35 -37 17 -42 -21 -6 -20 19 -26 31 -17 31 8 0
25 -11 38 -25z m100 -75 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4
10 6 0 11 -4 11 -10z"/>
<path d="M3608 4903 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M3658 4883 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3708 4873 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3918 4828 c12 -6 22 -15 22 -20 0 -4 5 -8 10 -8 19 0 10 17 -15 28
-33 15 -48 14 -17 0z"/>
<path d="M4041 4787 c15 -8 36 -24 48 -36 11 -11 26 -21 31 -21 18 0 10 19
-12 31 -13 7 -25 15 -28 20 -3 4 -19 10 -35 14 l-30 7 26 -15z"/>
<path d="M4080 4716 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 -5 3
-10 -1 -10 -9z"/>
<path d="M4160 4700 c0 -5 4 -10 9 -10 14 0 66 -53 63 -64 -2 -6 -1 -8 3 -5
10 10 97 -79 90 -91 -4 -6 -2 -9 3 -8 19 6 58 -57 61 -97 2 -47 11 -61 11 -17
l0 30 28 -27 c15 -15 36 -44 47 -64 11 -20 23 -37 27 -37 11 0 11 1 -10 40
-27 52 -74 120 -83 120 -4 0 -10 8 -13 18 -6 18 -102 120 -173 185 -42 37 -63
46 -63 27z"/>
<path d="M3041 4614 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4268 4543 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3363 4483 c15 -2 39 -2 55 0 15 2 2 4 -28 4 -30 0 -43 -2 -27 -4z"/>
<path d="M3528 4473 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M3598 4463 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M3791 4368 c-1 -20 4 -28 15 -28 12 0 14 4 7 13 -6 6 -13 19 -16 27
-3 9 -6 3 -6 -12z"/>
<path d="M3860 4290 c0 -5 4 -10 10 -10 5 0 20 -19 32 -42 16 -31 23 -38 26
-25 2 9 8 14 14 11 7 -4 8 -2 4 4 -4 6 -13 9 -21 5 -10 -3 -15 1 -15 14 0 11
-8 27 -18 36 -21 19 -32 22 -32 7z"/>
<path d="M4495 4290 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M4551 4144 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M3043 3995 c0 -44 2 -61 4 -37 2 23 2 59 0 80 -2 20 -4 1 -4 -43z"/>
<path d="M4566 4033 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"/>
<path d="M3520 3952 c0 -5 8 -12 18 -16 9 -3 23 -9 30 -12 6 -3 12 -1 12 4 0
5 -8 12 -17 16 -10 3 -24 9 -30 12 -7 3 -13 1 -13 -4z"/>
<path d="M4638 3770 c-3 -16 -1 -30 4 -30 4 0 8 14 8 30 0 17 -2 30 -4 30 -2
0 -6 -13 -8 -30z"/>
<path d="M3053 3710 c0 -25 2 -35 4 -22 2 12 2 32 0 45 -2 12 -4 2 -4 -23z"/>
<path d="M3777 3733 c-8 -13 12 -63 26 -63 6 0 5 5 -1 13 -5 6 -12 23 -14 37
-2 16 -6 21 -11 13z"/>
<path d="M4643 3660 c0 -25 2 -35 4 -22 2 12 2 32 0 45 -2 12 -4 2 -4 -23z"/>
<path d="M3053 3550 c0 -25 2 -35 4 -22 2 12 2 32 0 45 -2 12 -4 2 -4 -23z"/>
<path d="M1836 3433 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"/>
<path d="M4600 3419 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M1850 3365 c0 -8 4 -15 10 -15 5 0 7 7 4 15 -4 8 -8 15 -10 15 -2 0
-4 -7 -4 -15z"/>
<path d="M3791 3334 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4580 3339 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M1869 3293 c9 -18 9 -23 -1 -23 -9 0 -9 -3 0 -12 7 -7 12 -20 12 -30
0 -10 5 -18 11 -18 5 0 7 5 3 11 -4 7 -7 24 -6 40 0 15 -7 34 -15 41 -15 12
-15 11 -4 -9z"/>
<path d="M4555 3281 c-3 -5 -1 -12 5 -16 5 -3 10 1 10 9 0 18 -6 21 -15 7z"/>
<path d="M3041 3174 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2000 2971 c0 -6 4 -13 10 -16 6 -3 7 1 4 9 -7 18 -14 21 -14 7z"/>
<path d="M2019 2922 c6 -12 16 -24 22 -28 8 -4 8 -1 0 14 -6 12 -16 24 -22 28
-8 4 -8 1 0 -14z"/>
<path d="M2240 2651 c0 -12 39 -35 46 -28 3 2 -7 12 -21 21 -14 9 -25 12 -25
7z"/>
<path d="M2310 2590 c0 -5 10 -15 23 -21 22 -12 22 -12 3 10 -21 23 -26 26
-26 11z"/>
<path d="M2400 2511 c0 -5 7 -11 15 -15 8 -3 15 -1 15 3 0 5 -7 11 -15 15 -8
3 -15 1 -15 -3z"/>
<path d="M2625 2510 c3 -5 12 -7 20 -3 21 7 19 13 -6 13 -11 0 -18 -4 -14 -10z"/>
<path d="M2448 2473 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2480 2451 c0 -5 7 -12 16 -15 14 -5 15 -4 4 9 -14 17 -20 19 -20 6z"/>
<path d="M3508 2193 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M4488 1973 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M1720 1960 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M1805 1960 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M1848 1963 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M2407 1938 c-9 -19 -37 -88 -63 -154 -68 -175 -83 -204 -105 -204
-10 0 -19 -4 -19 -10 0 -6 27 -10 60 -10 33 0 60 4 60 10 0 6 -9 10 -19 10
-28 0 -31 10 -19 58 l10 42 59 0 c33 0 62 1 67 3 4 1 14 -20 24 -48 l16 -50
-24 -3 c-13 -2 -24 -8 -24 -13 0 -5 43 -9 95 -9 57 0 95 4 95 10 0 6 -6 10
-13 10 -7 0 -36 58 -66 133 -30 72 -60 142 -67 154 -8 12 -14 29 -14 37 0 8
-6 21 -13 28 -8 7 -16 20 -18 27 -3 9 -11 1 -22 -21z m10 -20 c-3 -8 -6 -5 -6
6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m9 -183 l6 -35 -51 0 c-28 0 -51 3 -51 7
0 4 12 39 27 77 l27 69 18 -42 c9 -22 20 -56 24 -76z"/>
<path d="M2808 1963 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3364 1955 c19 -47 20 -51 5 -32 -13 16 -18 6 -58 -95 -85 -218 -90
-230 -119 -243 -37 -17 -25 -25 38 -25 33 0 60 4 60 10 0 6 -11 10 -23 10 -34
0 -36 6 -18 55 l16 45 66 0 66 0 11 -39 c16 -53 15 -61 -8 -61 -11 0 -20 -4
-20 -10 0 -6 38 -10 95 -10 57 0 95 4 95 10 0 6 -6 10 -13 10 -7 0 -35 52 -61
118 -73 176 -118 272 -129 272 -5 0 -6 -7 -3 -15z m-5 -170 c30 -84 29 -85
-29 -85 -27 0 -50 4 -50 10 0 13 52 142 55 138 2 -2 13 -30 24 -63z"/>
<path d="M3800 1960 l-55 -7 58 -1 c32 -1 57 2 57 8 0 6 -1 9 -2 8 -2 -1 -28
-4 -58 -8z"/>
<path d="M5190 1958 c0 -4 5 -10 10 -13 6 -3 10 -50 10 -105 0 -112 -6 -117
-54 -48 -16 22 -53 69 -83 104 -54 64 -54 64 -106 63 -34 0 -45 -3 -33 -8 17
-7 18 -8 3 -14 -14 -5 -17 -29 -19 -179 -3 -171 -3 -173 -25 -176 -47 -7 -21
-22 37 -22 58 0 84 15 38 22 -23 3 -23 5 -26 176 -2 156 5 205 21 155 4 -10
52 -83 108 -163 56 -80 109 -157 118 -172 9 -16 19 -28 24 -28 4 0 7 90 7 200
0 127 4 200 10 200 6 0 10 3 10 8 0 4 -11 7 -25 7 -14 0 -25 -3 -25 -7z m-187
-15 c-13 -2 -23 -9 -23 -14 0 -6 -4 -7 -10 -4 -21 13 -9 25 23 24 23 -1 26 -3
10 -6z"/>
<path d="M1941 1950 c15 -6 30 -21 33 -33 10 -37 7 -252 -4 -284 -13 -37 -46
-53 -105 -53 l-45 0 0 164 c0 90 -3 171 -6 180 -4 11 -19 16 -50 16 l-44 0 0
-180 0 -180 -25 0 c-14 0 -25 -4 -25 -10 0 -6 46 -10 118 -10 157 0 205 14
257 77 38 46 40 49 40 124 0 71 -2 80 -31 115 -39 48 -71 72 -109 79 -30 5
-30 5 -4 -5z m-141 -30 c0 -6 -11 -8 -27 -4 -40 10 -41 14 -5 14 18 0 32 -5
32 -10z m197 -22 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z"/>
<path d="M2709 1907 c-4 -62 6 -78 16 -24 7 36 23 61 45 70 9 3 3 6 -13 6 -16
1 -26 -3 -22 -9 3 -5 2 -10 -4 -10 -5 0 -11 6 -14 13 -2 6 -6 -14 -8 -46z"/>
<path d="M2898 1953 c21 -8 13 -30 -13 -35 -14 -3 -25 -2 -25 3 0 4 10 10 23
12 20 4 19 4 -4 6 -16 0 -29 -5 -33 -15 -3 -9 -6 -90 -6 -180 l0 -164 -25 0
c-14 0 -25 -4 -25 -10 0 -6 42 -10 105 -10 63 0 105 4 105 10 0 6 -11 10 -25
10 l-25 0 0 173 c0 161 -1 174 -20 190 -11 9 -26 17 -33 16 -9 0 -9 -2 1 -6z"/>
<path d="M3038 1937 c11 -12 28 -38 36 -57 l16 -35 -3 47 c-2 43 -4 48 -27 49
l-25 1 25 8 c23 7 22 8 -9 9 l-34 1 21 -23z"/>
<path d="M4387 1932 c-21 -16 -48 -49 -63 -80 -23 -44 -26 -61 -21 -107 8 -79
41 -132 102 -163 93 -48 200 -24 265 58 28 35 35 54 38 101 6 74 -1 97 -37
138 -52 58 -143 115 -95 59 12 -15 19 -37 19 -63 -4 -255 -4 -255 -30 -280
-37 -37 -122 -29 -141 14 -4 9 -9 85 -10 168 -3 147 -2 153 19 168 14 10 16
15 7 15 -8 -1 -32 -13 -53 -28z"/>
<path d="M3990 1939 c0 -5 4 -8 9 -5 11 7 15 -259 5 -299 -8 -32 -51 -55 -104
-55 l-40 0 0 143 c0 78 -3 159 -6 180 -7 37 -7 37 -55 37 l-49 0 0 -180 0
-180 -25 0 c-14 0 -25 -5 -25 -11 0 -19 278 -7 316 13 101 54 139 192 79 285
-37 57 -105 103 -105 72z m-162 -16 c-10 -2 -28 -2 -40 0 -13 2 -5 4 17 4 22
1 32 -1 23 -4z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
